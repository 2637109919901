import React, { Fragment } from 'react';
import { Script } from 'gatsby';

export const RootElement = ({ children }) => {
  return (
    <Fragment>
      <Script
        strategy="post-hydrate"
        id="CookieDeclaration"
        src="https://consent.cookiebot.com/c01276dc-3395-418e-afcb-83e26d23ce44/cd.js"
        type="text/javascript"
      ></Script>
      <Script
        async
        id="gtagManager"
        strategy="post-hydrate"
        src="https://www.googletagmanager.com/gtag/js?id=G-CPD641WMLY"
      />
      <Script
        strategy="post-hydrate"
        dangerouslySetInnerHTML={{
          __html: `window.dataLayer = window.dataLayer || [];
      function gtag(){dataLayer.push(arguments);}
      gtag('js', new Date());
  
      gtag('config', 'G-CPD641WMLY', {'send_page_view':false});
      gtag('config', 'GTM-KJSJGRLX', {'send_page_view':false});
`,
        }}
      />
      <Script
        id="wisepop"
        // strategy="post-hydrate"
        data-cfasync="false"
        type="text/javascript"
        dangerouslySetInnerHTML={{
          __html: `
      (function(W,i,s,e,P,o,p){W['WisePopsObject']=P;W[P]=W[P]||function(){(W[P].q=W[P].q||[]).push(arguments)},W[P].l=1*new Date();o=i.createElement(s),p=i.getElementsByTagName(s)[0];o.defer=1;o.src=e;p.parentNode.insertBefore(o,p)})(window,document,'script','//loader.wisepops.com/get-loader.js?v=1&site=4PgnMiKgVx','wisepops');
      `,
        }}
      />
      {/* <div id="awb-clickToChat" />
      <Script
        src="https://swims.akio.cloud/aip/widget/akio-widget/js/akio-widget-launcher.js"
        id="akioWidgetLauncherUrl"
        data-uuid="8d3de292-65c9-40fc-925c-31bdb138bd53"
        // strategy="post-hydrate"
        data-tenantuuid="7caf7f9f-0e33-40fd-a1cb-d4d02ab80069"
      />
      <meta
        name="viewport"
        content="width=device-width, initial-scale=1.0, maximum-scale=1.0"
      /> */}
      <Script
        type="text/javascript"
        id="hs-script-loader"
        // strategy="post-hydrate"
        src="//js-eu1.hs-scripts.com/143839353.js"
      />
      {children}
    </Fragment>
  );
};
