import './src/sass/global.scss';
import React from 'react';
import { PageContextProvider } from './src/components/context/pageContext';
import { WindowSizeContextProvider } from './src/components/context/useWindowSizeContext';
import { Layout } from './src/components/layout/Layout';
import { MapContextProvider } from './src/components/context/mapContext';
import { XpContextProvider } from './src/components/context/xpContext';
import { GeoContextProvider } from './src/components/context/useGeoContext';
import { RootElement } from './src/utils/RootElement';

export const wrapPageElement = ({ element, props }) => {
  return (
    <PageContextProvider pageContext={props.pageContext}>
      <GeoContextProvider>
        <WindowSizeContextProvider>
          <MapContextProvider>
            <XpContextProvider>
              <Layout>{element}</Layout>
            </XpContextProvider>
          </MapContextProvider>
        </WindowSizeContextProvider>
      </GeoContextProvider>
    </PageContextProvider>
  );
};

export const wrapRootElement = ({ element }) => {
  return <RootElement>{element}</RootElement>;
};

export const onRouteUpdate = ({ location }) => {
  if (process.env.NODE_ENV !== 'production') {
    return null;
  }

  const pagePath = location ? location.pathname + location.search + location.hash : undefined;

  setTimeout(() => {
    if (typeof window.gtag === 'function') {
      window.gtag('event', 'page_view', { page_path: pagePath });
    }
  }, 100);

  return true;
};
