import { Maybe, SanityCountrySettings, SanityPage } from 'graphql-types';
import React, { useEffect, useState } from 'react';
import { usePageContext } from '../context/pageContext';
import { LocalLink } from '../html-tag/localLink';
import * as styles from './menu.module.scss';
import { SubMenu } from './SubMenu';
import FR from '../../../static/svg/winback_icon_country_fr_38x25.svg';
import GB from '../../../static/svg/winback_icon_country_en_38x25.svg';
import ES from '../../../static/svg/winback_icon_country_sp_38x25.svg';
import IT from '../../../static/svg/winback_icon_country_it_38x25.svg';
import GE from '../../../static/svg/winback_icon_country_de_38x25.svg';
import { graphql, Link, useStaticQuery } from 'gatsby';
import ArrowDownOrange from '../../../static/svg/arrow-down-orange.svg';
import { MenuQuery, SanityMenuItem, SanityPageExpertise } from '../../../graphql-types';
import { findTranslation } from '../../utils/findTranslation';
import { constructSlug } from '../../utils/constructSlug';
import { isCountryAllowed } from '../../utils/isCountryAllowed';
import { useGeoContext } from '../context/useGeoContext';

interface Props {
  isOpened: boolean;
  setIsOpened: (value: boolean) => void;
}

const options = [
  { value: 'en', label: 'English', icon: <GB /> },
  { value: 'fr', label: 'French', icon: <FR /> },
  { value: 'it', label: 'Italian', icon: <IT /> },
  { value: 'es', label: 'Spanish', icon: <ES /> },
  { value: 'de', label: 'German', icon: <GE /> },
];

export const Menu: React.FC<Props> = ({ isOpened, setIsOpened }) => {
  const { slugs, lang, defaultLang, setLang, originalPath } = usePageContext();
  const { country } = useGeoContext();
  const { sanityHeader } = useStaticQuery<MenuQuery>(QUERY);
  const [goSubMenu, setGoSubMenu] = useState<number>(-1);
  const data = sanityHeader?.menuHeader;

  const onClosed = () => {
    // setGoSubMenu(-1);
    setIsOpened(!isOpened);
  };

  useEffect(() => {
    if (!isOpened) {
      setGoSubMenu(-1);
    }
  }, [isOpened]);

  return (
    <div className={styles.wrapper}>
      <ul>
        {data?.menuItemsList
          ?.filter((item) =>
            isCountryAllowed(
              country,
              item?.correspondingInternalPage?.correspondingPage
                ?.countrySettings as SanityCountrySettings,
            ),
          )
          ?.map((menuItem, index) => {
            let slug: Maybe<string> | undefined;

            if (menuItem?.correspondingInternalPage?.experience) {
              slug = `/experience`;
            } else
              slug = constructSlug({
                destination: menuItem?.correspondingInternalPage?.correspondingPage as
                  | SanityPage
                  | SanityPageExpertise,
              });

            return (
              <React.Fragment key={`menuItem-${index}`}>
                <li>
                  {menuItem?.correspondingInternalPage?.activeMenuItem ? (
                    <div
                      className={slug && originalPath.includes(slug) ? styles.bold : ''}
                      onClick={() => {
                        if (menuItem?.correspondingInternalPage?.activeMenuItem) {
                          setGoSubMenu(goSubMenu === index ? -1 : index);
                        } else onClosed();
                      }}
                    >
                      <div className={styles.arrowMenu}>
                        {findTranslation(menuItem?._rawLabelItem, lang, defaultLang)}
                        <ArrowDownOrange />
                      </div>
                    </div>
                  ) : (
                    <LocalLink
                      to={slug ?? ''}
                      onClick={() => {
                        if (menuItem?.correspondingInternalPage?.activeMenuItem) {
                          setGoSubMenu(goSubMenu === index ? -1 : index);
                        } else onClosed();
                      }}
                    >
                      {findTranslation(menuItem?._rawLabelItem, lang, defaultLang)}
                    </LocalLink>
                  )}
                </li>

                {goSubMenu === index && (
                  <div className={styles.subMenu}>
                    <SubMenu
                      data={menuItem as SanityMenuItem}
                      setGoSubMenu={setGoSubMenu}
                      isOpened={isOpened}
                      setIsOpened={setIsOpened}
                    />
                  </div>
                )}
              </React.Fragment>
            );
          })}
      </ul>
      <div className={styles.flags}>
        {options.map((country, index) => {
          return (
            <Link
              to={
                country.value === 'en'
                  ? `${originalPath}`
                  : `/${country.value}${slugs?.[country.value] ?? ''}`
              }
              key={`country-${index}`}
              onClick={() => setLang(country.value)}
              className={styles.country}
            >
              {country.value.toUpperCase()}
            </Link>
          );
        })}
      </div>
    </div>
  );
};

export const QUERY = graphql`
  query Menu {
    sanityHeader {
      menuHeader {
        menuItemsList {
          _rawLabelItem(resolveReferences: { maxDepth: 10 })
          correspondingInternalPage {
            allSubPages
            _rawAllLinkName(resolveReferences: { maxDepth: 10 })
            allLinkPosition
            activeMenuItem
            experience
            correspondingPage {
              ... on SanityPage {
                _type
                __typename
                id
                _rawPageSlug(resolveReferences: { maxDepth: 10 })
                countrySettings {
                  ...countrySettingsFragment
                }
              }
              ... on SanityPageExpertise {
                _type
                __typename
                id
                _rawPageExpertiseSlug(resolveReferences: { maxDepth: 10 })
                countrySettings {
                  ...countrySettingsFragment
                }
                _rawPageSlug: _rawPageExpertiseSlug(resolveReferences: { maxDepth: 10 })
              }
            }
            menuSubItemsList {
              _rawLabelSubItem(resolveReferences: { maxDepth: 10 })
              allSubPages
              _rawAllLinkName(resolveReferences: { maxDepth: 10 })
              allLinkPosition
              correspondingSubPage {
                ... on SanityPage {
                  _type
                  __typename
                  id
                  _rawPageSlug(resolveReferences: { maxDepth: 10 })
                  _rawPageTitle(resolveReferences: { maxDepth: 10 })
                  countrySettings {
                    ...countrySettingsFragment
                  }
                }
                ... on SanityPageExpertise {
                  _type
                  __typename
                  id
                  _rawPageExpertiseSlug(resolveReferences: { maxDepth: 10 })
                  _rawPageExpertiseTitle(resolveReferences: { maxDepth: 10 })

                  countrySettings {
                    ...countrySettingsFragment
                  }
                }
              }
              correspondingSubPageProduct {
                ... on SanityPageProduct {
                  _type
                  __typename
                  id
                  _rawPageProductSlug(resolveReferences: { maxDepth: 10 })
                  _rawPageProductTitle(resolveReferences: { maxDepth: 10 })
                  countrySettings {
                    ...countrySettingsFragment
                  }
                }
                ... on SanityPageExpertise {
                  _type
                  __typename
                  id
                  _rawPageExpertiseSlug(resolveReferences: { maxDepth: 10 })
                  _rawPageExpertiseTitle(resolveReferences: { maxDepth: 10 })
                  countrySettings {
                    ...countrySettingsFragment
                  }
                }
                ... on SanityPage {
                  _type
                  __typename
                  id
                  _rawPageSlug(resolveReferences: { maxDepth: 10 })
                  _rawPageTitle(resolveReferences: { maxDepth: 10 })
                  countrySettings {
                    ...countrySettingsFragment
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
