module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-facebook-pixel/gatsby-browser.js'),
      options: {"plugins":[],"pixelId":"2244192632407145"},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Winback","short_name":"Winback","start_url":"/","background_color":"#ffffff","theme_color":"#ffffff","display":"standalone","icons":[{"src":"/favicon-16x16.png","sizes":"16x16","type":"image/png"},{"src":"/favicon-32x32.png","sizes":"32x32","type":"image/png"},{"src":"/favicon-96x96.png","sizes":"96x96","type":"image/png"},{"src":"/favicon-256x256.png","sizes":"256x256","type":"image/png"}],"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":null},
    },{
      plugin: require('../gatsby-browser.tsx'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
